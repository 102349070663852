import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "root",
    redirect: "/index",
  },
  {
    path: "/index",
    redirect: "/index/home",
    component: () => import("@/views/IndexView.vue"),
    children: [
      {
        path: "/index/home",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/index/login",
        name: "login",
        component: () => import("@/views/LoginView.vue"),
      },
      {
        path: "/index/regist",
        name: "regist",
        component: () => import("@/views/RegistView.vue"),
      },
      {
        path: "/index/technology",
        name: "technology",
        component: () => import("@/views/TechnologyView.vue"),
      },
      {
        path: "/index/technology/procyon",
        name: "procyon",
        meta: {
          sort: "technology",
        },
        component: () => import("@/views/ProcyonView.vue"),
      },
      {
        path: "/index/technology/canopus",
        name: "canopus",
        meta: {
          sort: "technology",
        },
        component: () => import("@/views/CanopusView.vue"),
      },
      {
        path: "/index/technology/rigel",
        name: "rigel",
        meta: {
          sort: "technology",
        },
        component: () => import("@/views/RigelView.vue"),
      },
      {
        path: "/index/technology/cloud",
        name: "cloud",
        meta: {
          sort: "technology",
        },
        component: () => import("@/views/CloudView.vue"),
      },
      {
        path: "/index/case",
        name: "case",
        component: () => import("@/views/CaseView.vue"),
      },
      {
        path: "/index/case/cnpc",
        name: "cnpc",
        meta: {
          sort: "case",
        },
        component: () => import("@/views/CnpcView.vue"),
      },
      {
        path: "/index/case/cpyy",
        name: "cpyy",
        meta: {
          sort: "case",
        },
        component: () => import("@/views/CpyyView.vue"),
      },
      {
        path: "/index/case/ningbo",
        name: "ningbo",
        meta: {
          sort: "case",
        },
        component: () => import("@/views/NingboView.vue"),
      },
      {
        path: "/index/case/klsz",
        name: "klsz",
        meta: {
          sort: "case",
        },
        component: () => import("@/views/KlszView.vue"),
      },
      {
        path: "/index/news",
        name: "news",
        component: () => import("@/views/NewsView.vue"),
      },
      {
        path: "/index/detail",
        name: "detail",
        meta: {
          sort: "news",
        },
        component: () => import("@/views/NewsDetailView.vue"),
      },
      {
        path: "/index/detail2",
        name: "detail2",
        meta: {
          sort: "news",
        },
        component: () => import("@/views/NewsDetailView2.vue"),
      },
      {
        path: "/index/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/AboutView.vue"),
      },
      {
        path: "/index/joinUs",
        name: "joinUs",
        component: () => import("@/views/JoinView.vue"),
      },
      {
        path: "/index/joinUsDetail",
        name: "joinUsDetail",
        meta: {
          sort: "joinUs",
        },
        component: () => import("@/views/JoinDetailView.vue"),
      },
      {
        path: "/index/cooperativePartner",
        name: "cooperativePartner",
        component: () => import("@/views/PartnerView.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
